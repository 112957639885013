import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import micalogo from '../../assets/images/mica-logo.svg';
import { Collapse } from 'bootstrap';

const Header = (props) => {
    const navigate = useNavigate()
    const [isNavOpen, setIsNavOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            scrollToSection(location.hash.substring(1));
        }
    }, [location]);

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        if (isNavOpen) {
            // Manually hide the navbar if it's open
            const navbarCollapse = document.querySelector('.navbar-collapse');
            if (navbarCollapse) {
                const bootstrapCollapse = new Collapse(navbarCollapse, { toggle: false });
                bootstrapCollapse.hide();
            }
            setIsNavOpen(false); // Update state
        }
    };

    const toggleNavbar = () => {
        setIsNavOpen(prevState => !prevState);
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarCollapse) {
            const bootstrapCollapse = new Collapse(navbarCollapse, { toggle: false });
            if (isNavOpen) {
                bootstrapCollapse.hide();
            } else {
                bootstrapCollapse.show();
            }
        }
    };


    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const handleLogoClick = (e) => {
        e.preventDefault();
        navigate('/');
        scrollToTop();
    }


    return (
        <header className='header-section'>
            <div className='container-fluid'>
                <nav className="navbar navbar-expand-lg">
                    <Link to="/" onClick={handleLogoClick}><img src={micalogo} alt='Mica Logo' /></Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        aria-controls="navbarSupportedContent"
                        aria-expanded={isNavOpen ? "true" : "false"}
                        aria-label="Toggle navigation"
                        onClick={toggleNavbar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav d-flex">
                            <li className="nav-item">
                                <Link to="/#wallet" className='nav-link'>{props.wallet}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#feature" className='nav-link'>{props.features}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/" className='nav-link'>{props.build}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/" className='nav-link'>{props.support}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/#faq" className='nav-link'>{props.faq}</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

Header.defaultProps = {
    wallet: 'WALLET',
    features: 'FEATURES',
    build: 'BUILD',
    support: 'SUPPORT',
    faq: 'FAQs',
};

export default Header;



// import React, { useEffect, useState } from "react";

// const Header = (props) => {
//     const [path, setPath] = useState("");
//     const [navActive, setNavActive] = useState("home");
//     const [show, setShow] = useState(false);


//     useEffect(() => {
//         const navlinks = document.querySelectorAll(".nav-link");
//         navlinks.forEach((a) => {
//             a.addEventListener("click", function () {
//                 navlinks.forEach((a) => a.classList.remove("active"));
//                 this.classList.add("active");
//                 setTimeout(() => {
//                     document.getElementById("close-btn")?.click();
//                 }, 200);
//             });
//         });
//     }, []);

//     useEffect(() => {
//         setPath(window.location.pathname);
//     }, []);

//     useEffect(() => {
//         let lastScroll = 100;
//         const onScroll = () => {
//             if (window.pageYOffset > lastScroll) {
//                 setShow(true);
//             } else if (window.pageYOffset < lastScroll) {
//                 setShow(false);
//             }
//             // lastScroll = window.pageYOffset;
//         };
//         window.addEventListener("scroll", onScroll, { passive: true });
//         return () => window.removeEventListener("scroll", onScroll);
//     }, []);

//     return (
//         <>
//             <header className={`wrapper ${show ? "top-fixed" : ""} position-fixed top-0 w-100`}>
//                 <div className="">
//                     <nav className={` navbar navbar-expand-xl`}>
//                         <a className={`navbarbrand navbar-brand`} href="/">
//                             <img src={"/assets/logo.png"} alt="" className={`img `} />
//                         </a>
//                         <button
//                             className="navbar-toggler"
//                             type="button"
//                             data-bs-toggle="offcanvas"
//                             data-bs-target="#offcanvasRight"
//                             aria-controls="offcanvasRight"
//                         >
//                             <span className="navbar-toggler-icon"></span>
//                         </button>
//                         <div className={` offcanvas offcanvas-start`} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
//                             <div className="offcanvas-header">
//                                 <h5 className="offcanvas-title" id="offcanvasRightLabel">
//                                     <img src="/assets/logo.png" alt="" className={`img2 img-fluid`} />
//                                 </h5>
//                                 <button
//                                     id="close-btn"
//                                     type="button"
//                                     className="btn-close text-white"
//                                     data-bs-dismiss="offcanvas"
//                                     aria-label="Close"
//                                 ></button>
//                             </div>
//                             <div className="offcanvas-body align-items-center">
//                                 <ul className={`navbarnav navbar-nav gap-1 gap-xlg-3 ms-auto mb-2 mb-lg-0 align-items-start align-items-xl-center`}>
//                                     <li className={`navitem nav-item ${navActive === "home" && path == "/" ? "navactive" : ""}`} onClick={() => setNavActive("home")}>
//                                         <a className={`navlink nav-link `} href="/#">
//                                             Home
//                                         </a>
//                                     </li>
//                                     <li className={`navitem nav-item ${navActive === "about" ? "navactive" : ""}`} onClick={() => setNavActive("about")}>
//                                         <a className={`navlink nav-link `} href="/#about">
//                                             ABOUT
//                                         </a>
//                                     </li>
//                                     <li className={`navitem nav-item ${navActive === "usecase" ? "navactive" : ""}`} onClick={() => setNavActive("usecase")}>
//                                         <a className={`navlink nav-link `} href="/#usecase">
//                                             USECASE
//                                         </a>
//                                     </li>
//                                     <li className={`navitem nav-item ${navActive === "faq" ? "navactive" : ""}`} onClick={() => setNavActive("faq")}>
//                                         <a className={`navlink nav-link `} href="/#faq">
//                                             FAQ
//                                         </a>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </nav>
//                 </div>
//             </header>
//         </>
//     );
// };

// export default Header;

