import Banner from './Banner'
import Home from './Home'

const Main = () => {
  return (
    <>
    <Banner />
    <Home />
    </>
  )
}


export default Main
