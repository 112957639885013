import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom'

import './assets/css/Custom.css';
//import './assets/css/Webfonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Main from './components/landingpage/Main';
import Terms from './components/terms'
import Privacy from './components/privacy'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

import  { Toaster } from 'react-hot-toast';


function App() {

  useEffect(() => {
    AOS.init();
  },[])

  return (
    <div className="App">
      <BrowserRouter>    
          <Header/>
          <Routes>
            <Route path='/' element={<Main/>} />
            <Route path='/terms' element={<Terms/>} />
            <Route path='/privacy-policy' element={<Privacy/>} />
          </Routes>
          <Footer/>
          <Toaster/>
      </BrowserRouter>

    </div>
  );
}

export default App;
