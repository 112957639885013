import { Link } from 'react-router-dom'

import footerlogo from '../../assets/images/footer-logo.svg'
import facebook from '../../assets/images/facebook.svg'
import instrgram from '../../assets/images/instrgram.svg'
import twitter from '../../assets/images/twitter.svg'
import address from '../../assets/images/address.svg'
import phone from '../../assets/images/phone.svg'
import toast from 'react-hot-toast';
import { useState } from 'react'

const Footer = (e) => {
  const [email, setEmail]=useState("")
  function handleSubmit(e) {
    e.preventDefault();
    toast.success('Successfully Submit!')
    setEmail("")
  }
  return (
    <>
      <footer className="footer TopBottomSpace">
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='footerlogo'>
                <img src={footerlogo} alt='Footer Logo' />
                <p>A new way to make the payments easy, reliable and 100% secure. </p>
                <ul className='SocialIcon d-flex mb-md-0 mb-5'>
                  <span><Link to=""><img src={facebook} alt='Footer Logo' /></Link></span>
                  <span><Link to=""><img src={instrgram} alt='Footer Logo' /></Link></span>
                  <span><Link to=""><img src={twitter} alt='Footer Logo' /></Link></span>
                </ul>
              </div>
            </div>
            <div className='col-md-6 col-lg-3 ms-auto' >
              <ul className='ContactAdd'>
                <li className='d-flex mb-5'>
                  <img src={address} alt='address icon' />
                  <div className=''>
                    <h5>Email Address</h5>
                    <a href="mailto:info@micawallet.com">info@micawallet.com</a>
                  </div>
                </li>
                {/* <li className='d-flex mb-5'>
                  <img src={phone} alt='address icon' />
                  <div className=''>
                    <h5>Phone Number</h5>
                    <a href="tel:+917808900007">+91 7808900007</a>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className='col-lg-5'>
              <div className='NewsLetter'>
                <h4>SUBSCRIBE NEWSLETTER </h4>
                <p className='mt-3'>Stay updated! Subscribe to our newsletter for <br/> the latest news, updates, and exclusive offers.</p>
                <form className='SubscribeForm mt-5' onSubmit={handleSubmit}>
                  <input type='email' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Email Address' className='InputField' required /> <button className='btn' type='submit'>Subscribe</button>
                </form>
              </div>
            </div>
          </div>
          <div className='AllRightsReserved mt-5'>
            <div className='row mt-5'>
              <div className='col-md-6'>
                <div className='MiCaWallet'>© MiCA Wallet. All Rights Reserved 2024</div>
              </div>
              <div className='col-md-6'>
                <div className='TermLink d-flex justify-content-end'>
                  <Link to="/terms">Terms & Conditions</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>                
                </div>
              </div>
            </div>
          </div>

        </div>
      </footer>
    </>
  )
}


export default Footer
