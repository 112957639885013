import React, { useEffect } from 'react'

const TermsAndConditions = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo(0, 0);
        };
        scrollToTop();
    }, []);
    return (
        <>
            <section className='terms-section'>
                <div className='terms-heading-bg'>
                    <div className='container'>

                        <h1 className='terms-heading'>Terms and Conditions</h1>
                    </div>

                </div>
                <div class="container mt-5">

            <p>We regularly update these terms and conditions to reflect changes in our services or to comply with changes in law.</p>

            <p>At MiCA Wallet, we strive to ensure that our services are used responsibly and fairly. These terms and conditions, along with our Privacy Policy, explain who we are and outline the rules for using our websites (MiCA Wallet's website; MiCA Wallet Developer Portal; MiCA Wallet Connect) and services. In these terms, references to MiCA Wallet, we, our, or us refer to MiCA Wallet Network Limited and all our group companies doing business under the MiCA Wallet name.</p>

            <p>MiCA Wallet Network Limited, located at 20-22 Wenlock Road, London, England, N1 7GU (company number 09798383), is responsible for providing our services under these terms and conditions.</p>

            <h2>These terms and conditions cover the following sections:</h2>

            <ul style={{listStyle : "outside"}}>
                <li>How to contact us</li>
                <li>What these terms and conditions apply to</li>
                <li>User obligations</li>
                <li>Account information</li>
                <li>Service usage</li>
                <li>Fees and payments</li>
                <li>Cancellations and refunds</li>
                <li>Limitation of liability</li>
                <li>Privacy</li>
                <li>Changes to the terms</li>
                <li>Governing law</li>
            </ul>

            <h3 id="contact-us">1. How to contact us</h3>

            <p>For any questions, comments, requests, or complaints regarding these terms and conditions, please contact us via email at <a href="mailto:info@micawallet.com">info@micawallet.com</a> or by regular mail:</p>

            <p>Attention: Customer Support<br/>
                MiCA Wallet Network Ltd<br/>
                20-22 Wenlock Road<br/>
                London N1 7GU
            </p>

            <h3 id="terms-apply">2. What these terms and conditions apply to</h3>

            <p>These terms and conditions apply to your use of our websites and services.</p>

            <p>Our websites may provide links to other websites. We have no control over these third-party websites and are not responsible for their content or for how your personal information may be processed when you visit them. These terms and conditions do not extend to your use of such websites, and we encourage you to read their terms and conditions.</p>

            <h3 id="user-obligations">3. User obligations</h3>

            <p>When using our websites and services, you agree to:</p>

            <ul>
                <li>Provide accurate and complete information when creating an account.</li>
                <li>Update your information promptly if there are any changes.</li>
                <li>Keep your account credentials confidential and not share them with others.</li>
                <li>Use our services only for lawful purposes and in accordance with these terms.</li>
                <li>Not engage in any activity that could harm our services or interfere with other users' use of our services.</li>
            </ul>

            <h3 id="account-info">4. Account information</h3>

            <p>When you create an account with us, you must provide us with accurate and complete information. Failure to do so constitutes a breach of these terms, which may result in immediate termination of your account.</p>

            <p>You are responsible for safeguarding the password that you use to access the service and for any activities or actions under your password, whether your password is with our service or a third-party service.</p>

            <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

            <h3 id="service-usage">5. Service usage</h3>

            <p>You agree to use our services in compliance with all applicable laws and regulations. You are not permitted to:</p>

            <ul>
                <li>Use our services in any way that breaches any applicable local, national, or international law or regulation.</li>
                <li>Use our services in any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
                <li>Transmit any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</li>
                <li>Knowingly transmit any data, send, or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
            </ul>

            <h3 id="fees-payments">6. Fees and payments</h3>

            <p>Fees for our services are set out on our website and may be updated from time to time. Payments must be made in accordance with the instructions and terms provided at the time of purchase.</p>

            <p>Failure to make timely payments may result in suspension or termination of your access to our services.</p>

            <h3 id="cancellations-refunds">7. Cancellations and refunds</h3>

            <p>You may cancel your use of our services in accordance with our cancellation policy, which is available on our website. Refunds, if applicable, will be processed in accordance with our refund policy.</p>

            <h3 id="liability">8. Limitation of liability</h3>

            <p>To the maximum extent permitted by applicable law, MiCA Wallet Network Limited and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the services; (iv) any bugs, viruses, Trojan horses, or the like that may be transmitted to or through our services by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the services; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.</p>

            <h3 id="privacy">9. Privacy</h3>

            <p>Your use of our services is also governed by our Privacy Policy, which can be found on our website.</p>

            <h3 id="changes-terms">10. Changes to the terms</h3>

            <p>We may modify these terms and conditions from time to time. We will notify you of any changes by posting the new terms on our website. You are advised to review these terms periodically for any changes. Changes to these terms are effective when they are posted on this page.</p>

            <p>If you do not agree to the new terms, you must stop using the services. Your continued use of the services after the new terms have been posted constitutes your acceptance of the new terms.</p>

            <h3 id="governing-law">11. Governing law</h3>

            <p>These terms and conditions are governed by and construed in accordance with the laws of England and Wales. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
        </div>

            </section>
        </>
    )
}

export default TermsAndConditions