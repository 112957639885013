import { Link } from "react-router-dom";

import image01 from "../../assets/images/secure-img.png";
import mobilescreen from "../../assets/images/mobilescreen.png";
import walletimg from "../../assets/images/wallet-img.png";

import walleticon01 from "../../assets/images/wallet_icon01.svg";
import walleticon02 from "../../assets/images/wallet_icon02.svg";
import walleticon03 from "../../assets/images/wallet_icon03.svg";
import walleticon04 from "../../assets/images/wallet_icon04.svg";
import WebWallet from "../../assets/images/screen.gif";
import mobile from "../../assets/images/mobile.png";
import Faq from "./Faq";

const Home = () => {
  // Stay private and secure
  const arr = [
    {
      id: 1,
      Number: "01",
      Heading: "True ownership of your crypto assets",
      Description:
        "We secure your wallet, but don't control or have access to your private keys or secret phrase - only you do.",
    },
    {
      id: 2,
      Number: "02",
      Heading: "Verify Wallet",
      Description:
        "We don't track any personal information, including your IP address or balances.",
    },
    {
      id: 3,
      Number: "03",
      Heading: "Multi Tasking",
      Description:
        "Use our Encrypted Cloud Backup for increased wallet security.",
    },
    {
      id: 4,
      Number: "04",
      Heading: "Proactive alerts for risky transactions",
      Description:
        "Stay safe with alerts for risky address and dApp connections.",
    },
  ];

  // The most trusted cryptocurrency wallet
  const arr02 = [
    {
      id: 5,
      walleticon: walleticon01,
      wallet_heading: "The largest public crypto company",
      wallet_description: "We operate with financial transparency.",
    },
    {
      id: 6,
      walleticon: walleticon02,
      wallet_heading: "VYour assets are protected",
      wallet_description:
        "Our risk management measures are designed to protect your assets.",
    },
    {
      id: 7,
      walleticon: walleticon03,
      wallet_heading: "Get the help you need, when you need it",
      wallet_description:
        "You can always contact our support team for quick solutions to common problems.",
    },
    {
      id: 8,
      walleticon: walleticon04,
      wallet_heading: "Industry best practices",
      wallet_description:
        "MiCA Wallet supports a variety of the most popular digital currencies.",
    },
  ];

  return (
    <>
      {/* Stay private and secure */}
      <section className="TopBottomSpace" id="">
        <div className="container">
          <div className="row" data-aos="fade-down" data-aos-duration="1000">
            <div className="col-md-12 text-center">
              <div className="Heading">
                <h2>
                  Stay private <span className="primary-color">and secure</span>
                </h2>
                <p className="HeadingPara">
                  Rest easy knowing that our privacy and security measures keep
                  you in control of your data and digital assets, while also
                  keeping them safe.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center TopSpace">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="">
                <img src={image01} alt="Mica Logo" class="img-fluid" />
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="List">
                <ul className="ListUl">
                  {arr.map((data) => (
                    <li className="d-flex" key={data.id}>
                      <div className="Number">{data.Number}</div>
                      <div className="ListCnt">
                        <h4>{data.Heading}</h4>
                        <p>{data.Description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Number Counter */}
          <div className="row align-items-center justify-content-center mt-5">
            <div className="col-md-3 text-center">
              <div className="Counter">
                <span className="counter-number">100,00+</span>{" "}
                <span>Tokens</span>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="Counter">
                <span className="counter-number">5 Years</span>{" "}
                <span>Track record</span>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="Counter">
                <span className="counter-number">200+</span>{" "}
                <span>Countries</span>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="Counter">
                <span className="counter-number">100,00,00+</span>{" "}
                <span>Global usres</span>
              </div>
            </div>
          </div>
          {/* Number Counter End */}
        </div>
      </section>
      {/* Stay private and secure End */}

      {/* Simple. Seamless. */}
      <section className="SimpleSeamless TopBottomSpace" id="wallet">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 text-center"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="Heading White_Heading">
                <h2>
                  Simple. <span className="primary-color">Seamless</span>
                </h2>
                <p className="HeadingPara">
                  Enjoy a smooth mobile app and desktop experience with
                  easy-to-use, powerful tools to support your entire Web3
                  journey.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center TopSpace">
            <div
              className="col-md-5"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="Heading White_Heading">
                <h3>Deposit crypto easily from exchanges.</h3>
                <p className="HeadingPara mt-3">
                  Take control of your crypto. Avoid complicated steps and
                  deposit directly to your wallet from exchanges like MiCA
                  Wallet.
                </p>
                <div className="Button MobileApp">
                  <Link to="">Download Mobile App</Link>
                </div>
              </div>
            </div>
            <div
              className="col-md-7"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="mobilescreen">
                <img src={mobilescreen} alt="Mica Logo" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Simple. Seamless. End */}

      {/* The most trusted Cryptocurrency wallet */}
      <section className="TopBottomSpace" id="feature">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 text-center"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="Heading">
                <h2>
                  The most trusted <br />
                  <span className="primary-color">cryptocurrency wallet</span>
                </h2>
                <p className="HeadingPara">
                  Rest easy knowing that our privacy and security measures keep
                  you in control of your data and digital assets, while also
                  keeping them safe.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center TopSpace">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="">
                <img src={walletimg} alt="Mica Logo" className="img-fliud" />
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="List">
                <ul className="ListUl">
                  {arr02.map((data) => (
                    <li className="d-flex" key={data.id}>
                      <div className="IconImg">
                        <img src={data.walleticon} alt="Wallet Img" />
                      </div>
                      <div className="ListCnt WalletCnt">
                        <h4>{data.wallet_heading}</h4>
                        <p>{data.wallet_description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* The most trusted Cryptocurrency wallet End */}

      {/*Your one-stop, Web3 wallet */}
      <section className="SimpleSeamless TopBottomSpace">
        <div className="container">
          <div className="row align-items-center TopSpace">
            <div
              className="col-md-5"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="Heading White_Heading">
                <h2>
                  Your one-stop,
                  <span className="primary-color"> Web3 wallet</span>{" "}
                </h2>
                <p className="HeadingPara mt-3">
                  Take control of your crypto. Avoid complicated steps and
                  deposit directly to your wallet from exchanges like MiCA
                  Wallet.
                </p>
                {/* Tabs List */}
                <ul
                  style={{ border: "1px solid #173559" }}
                  class="nav nav-fill nav-tabs TabsList mt-5"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="fill-tab-0"
                      data-bs-toggle="tab"
                      href="#fill-tabpanel-0"
                      role="tab"
                      aria-controls="fill-tabpanel-0"
                      aria-selected="true"
                    >
                      {" "}
                      Mobile{" "}
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="fill-tab-1"
                      data-bs-toggle="tab"
                      href="#fill-tabpanel-1"
                      role="tab"
                      aria-controls="fill-tabpanel-1"
                      aria-selected="false"
                    >
                      {" "}
                      Extension{" "}
                    </a>
                  </li>
                </ul>
                {/* Tabs List Ends */}
                <div className="Button MobileApp">
                  <div class="tab-content pt-5" id="tab-content">
                    <div
                      class="tab-pane active"
                      id="fill-tabpanel-0"
                      role="tabpanel"
                      aria-labelledby="fill-tab-0"
                    >
                      <Link to="">Download Mobile App</Link>
                    </div>
                    <div
                      class="tab-pane"
                      id="fill-tabpanel-1"
                      role="tabpanel"
                      aria-labelledby="fill-tab-1"
                    >
                      <Link to="">Download Web Extension</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-7"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              {/* Tabs Content */}
              <div class="tab-content pt-5" id="tab-content">
                <div
                  class="tab-pane active"
                  id="fill-tabpanel-0"
                  role="tabpanel"
                  aria-labelledby="fill-tab-0"
                >
                  <div className="MobileExtension">
                    <img
                      src={WebWallet}
                      alt="Mica Logo"
                      className="img-fliud"
                    />
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="fill-tabpanel-1"
                  role="tabpanel"
                  aria-labelledby="fill-tab-1"
                >
                  <div className="MobileExtension">
                    <img src={mobile} alt="Mica Logo" className="img-fliud" />
                  </div>
                </div>
              </div>
              {/* Tabs Content End */}
            </div>
          </div>
        </div>
      </section>
      {/* Your one-stop, Web3 wallet End */}

      {/* Get the answer you need */}
      <Faq />
      {/* Get the answer you need End */}
    </>
  );
};

export default Home;
