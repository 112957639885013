import { Link } from 'react-router-dom'

import BannerImg from '../../assets/images/screen.gif';
import AppStore from '../../assets/images/app-store.png';
import GooglePlay from '../../assets/images/google-play.png';


const Banner = () => {

const Heading = "True crypto ownership Powerful";
const Heading02 = "Web3 experiences";
const bannerCnt = "The World's 1st ICO Platform That Offers Rewards and The platform helps investors to make easy to purchase and sell their tokens"
    
  return (
    <section className='BannerSec d-flex align-items-center'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-7' data-aos='fade-right' data-aos-duration='1000'>
                    <div className='BannerText'>
                        <h2>True crypto<br/> ownership Powerful <br/><span className='primary-color'>{Heading02}</span></h2>
                        <p className='para-text-color'>The World's 1st ICO Platform That Offers Rewards and The platform <br/> helps investors to make easy to purchase and sell their tokens</p>
                        <div className='App-Button d-flex'>
                            <Link to="/"><img src={AppStore} alt='Banner Img' /></Link>
                            <Link to="/"><img src={GooglePlay} alt='Banner Img' /></Link>
                        </div>
                        <div className='MiCaCompliant'>First Ever 100% MiCa Compliant Wallet</div>
                    </div>
                </div>
                <div className='col-md-5' data-aos='fade-left' data-aos-duration='1000'>
                    <div className='BannerImg'>
                        <img src={BannerImg} alt='Banner Img' className='img-fliud' />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}


export default Banner
