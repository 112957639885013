import React, { useState } from 'react'

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
  return (
    <>
    <section className="TopBottomSpace" id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" data-aos="fade-down" data-aos-duration="1000">
                            <div className="Heading">
                                <h2>Get the answer <span className="primary-color">you need</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-5">
                        <div className="col-md-12" data-aos="fade-down" data-aos-duration="1000">
                            <div className="accordion accordion-flush Faq" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === 0 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(0)}
                                        >
                                            What is MiCA Wallet?
                                        </button>
                                    </h2>
                                    <div
                                        className={`accordion-collapse collapse ${openIndex === 0 ? 'show' : ''}`}
                                    >
                                        <div className="accordion-body">
                                            MiCA stands for Markets in Crypto-Assets regulation, which is a proposed regulatory framework by the European Commission to regulate crypto-assets and their providers. A MiCA Wallet would be a cryptocurrency wallet that operates within the guidelines set forth by this regulation. The aim of MiCA is to provide a comprehensive regulatory framework to ensure consumer protection, market integrity, and financial stability within the crypto-asset market in the European Union.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === 1 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(1)}
                                        >
                                            How do I set up a MiCA wallet?
                                        </button>
                                    </h2>
                                    <div
                                        className={`accordion-collapse collapse ${openIndex === 1 ? 'show' : ''}`}
                                    >
                                        <div className="accordion-body">
                                            Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === 2 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(2)}
                                        >
                                            What cryptocurrencies are supported by MiCA wallet?
                                        </button>
                                    </h2>
                                    <div
                                        className={`accordion-collapse collapse ${openIndex === 2 ? 'show' : ''}`}
                                    >
                                        <div className="accordion-body">
                                            Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === 3 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(3)}
                                        >
                                            How secure is MiCA wallet?
                                        </button>
                                    </h2>
                                    <div
                                        className={`accordion-collapse collapse ${openIndex === 3 ? 'show' : ''}`}
                                    >
                                        <div className="accordion-body">
                                            Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the fourth item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === 4 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(4)}
                                        >
                                            Can I make transactions using MiCA wallet?
                                        </button>
                                    </h2>
                                    <div
                                        className={`accordion-collapse collapse ${openIndex === 4 ? 'show' : ''}`}
                                    >
                                        <div className="accordion-body">
                                            Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the fifth item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Faq